img{
  height: 128px;
  width: 128px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 5px solid rgb(247, 59, 100);
}

.container-squad-users {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}

.squad-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  padding: 5px;
  font-size: 12px;
  color: gray;
  font-style: italic;
  font-family: 'Oswald';
  max-width: 128px;
  text-align: center;
}

.user-name {
  color: #161616;
  font-size: 14px;
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
}

.user-company,
.user-squad,
.user-role,
.user-email {
  margin-bottom: 3px;
  font-weight: 500;
}
