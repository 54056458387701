html, body, #root{
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.search-container {
  z-index: 1;
  position: absolute;
  margin-top: 10px;
  left:0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=text] {
  width: 30%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url('../../assets/searchicon.png');
  background-position: 10px 10px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.network-box {
  z-index: 0;
  height: 100%;
  background-color: transparent;
  position: relative;
}